import React, { useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { GatsbyImage } from 'gatsby-plugin-image';
import { getShopifyImage } from 'gatsby-source-shopify';

import { useRemoveItemFromCart } from 'lib/shopify-helper';
import trackEvent from 'utils/trackEvent';

import * as styles from './LineItem.module.css';

function LineItem({ item }) {
  const [loading, setLoading] = useState(false);
  const removeItemFromCart = useRemoveItemFromCart();

  const { amount = 0, currencyCode } = item?.variant?.priceV2 || {};

  const handleRemove = async () => {
    setLoading(true);
    try {
      await removeItemFromCart(item.variant.id);
      trackEvent('removeFromCart', { item });
    } catch (error) {
      throw new Error(error);
    }
    setLoading(false);
  };

  const variantImage = {
    ...item?.variant?.image,
    originalSrc: item.variant.image.src,
  };

  const image = useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: 'constrained',
        crop: 'contain',
        width: 348,
        height: 348,
      }),
    [variantImage.src],
  );

  return (
    <li className={styles.container}>
      <div className={styles.image}>
        {image ? (
          <GatsbyImage
            className={styles.gatsbyImage}
            key={variantImage.src}
            image={image}
            alt={variantImage.altText ?? item.variant.title}
          />
        ) : null}
      </div>
      <div className={styles.detail}>
        <h3>{item?.title}</h3>
        <p>
          {/* <span>
            {item.variant.title !== 'Default Title' ? item?.variant?.title : ''}
          </span> */}
          <span>
            <FormattedNumber
              style="currency"
              value={amount}
              currency={currencyCode}
            />
          </span>
          {item?.variant?.selectedOptions
            ? item.variant.selectedOptions.map((option) =>
                option?.value !== 'Default Title' ? (
                  <span key={option.name}>{option.value}</span>
                ) : null,
              )
            : null}
          <span>
            <FormattedMessage
              id="cart.label.qty"
              values={{ quantity: item.quantity }}
            />
          </span>
        </p>
        <button
          type="button"
          disabled={loading}
          onClick={handleRemove}
          className={styles.button}
        >
          <FormattedMessage id="cart.general.remove" />
        </button>
      </div>
    </li>
  );
}

export default LineItem;
