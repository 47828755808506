import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Link } from 'gatsby';

import { useCart, useCartItems } from 'lib/shopify-helper';
import LineItem from './LineItem';
import trackEvent from 'utils/trackEvent';

import * as styles from './Cart.module.css';

const SITE_COUNTRY = process.env.GATSBY_SITE_COUNTRY;
const isJP = SITE_COUNTRY === 'jp';

const Cart = () => {
  const cart = useCart();
  const items = useCartItems();

  if (cart === null) {
    return (
      <p>
        <FormattedMessage id="cart.general.loading" />
      </p>
    );
  }

  const isEmpty = items?.length === 0;

  const { amount = 0, currencyCode } = cart?.lineItemsSubtotalPrice || {};

  const handleClick = () => {
    trackEvent('checkout', { items });
  };

  return isEmpty ? (
    <div className={styles.container}>
      <div className={styles.empty}>
        <p>
          <FormattedMessage id="cart.general.empty" />
        </p>
        <Link to="/" className={styles.button}>
          <FormattedMessage id="cart.general.continue_shopping" /> →
        </Link>
      </div>
    </div>
  ) : (
    <div className={styles.container}>
      <ul className={styles.items}>
        {items.map((item) => (
          <LineItem key={item.id} item={item} />
        ))}
      </ul>

      <div className={styles.summary}>
        <h2>
          <FormattedMessage id="cart.general.subtotal" />{' '}
          <FormattedNumber
            style="currency"
            value={amount}
            currency={currencyCode}
          />
        </h2>
        <p>
          <i>
            <FormattedMessage id="cart.general.taxes_and_shipping_at_checkout" />
          </i>
        </p>

        <a
          href={cart.webUrl}
          onClick={handleClick}
          className={styles.button}
          disabled={items?.length === 0}
        >
          <FormattedMessage id="cart.general.checkout" />
        </a>
      </div>
    </div>
  );
};

export default Cart;
