import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from 'components/Layout';
import Cart from 'components/Cart';
import useSiteMetadata from 'hooks/useSiteMetadata';

const CartPage = ({ location }) => {
  const { siteUrl } = useSiteMetadata();
  const intl = useIntl();

  return (
    <Layout location={location}>
      <GatsbySeo
        noindex
        title={intl.formatMessage({ id: 'cart.general.title' })}
        canonical={`${siteUrl}${location.pathname}`}
      />
      <h1>
        <FormattedMessage id="cart.general.title" />
      </h1>
      <Cart />
    </Layout>
  );
};

export default CartPage;
